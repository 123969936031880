import request from "tools/request.js"
import authority from 'tools/authority'

class post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;



		request.post('/user/user_login', {
			shouji: that.parent.ruleForm.username,
			mima: that.parent.ruleForm.passwd
		}).then(res => {

			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});


				localStorage.setItem("quanxian", JSON.stringify(res.data.quanxian));
				localStorage.setItem("user_headimg", res.data.user_headimg);
				localStorage.setItem("user_id", res.data.user_id);
				localStorage.setItem("user_nickname", res.data.user_nickname);
				localStorage.setItem("user_state", 1);
				localStorage.setItem("session", res.data.session);

				// let auth = new authority(that);

				// auth.set_quanxian2();




				setTimeout(function() {
					that.parent.$router.push({
						path: '/',
						query: {} 
					});
				}, 3000);


			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}



		})

	}
}

export default post
