<template>
	<div class="login ">
		<el-col class="login-center" :span="14">
			<el-card class="el-card2" shadow="never">
				<el-row>
					<el-col :span="13">
						<h1 class="login-a1">Sign In</h1>
						<div class="yu_a1">登陆到 星空羽球 管理后台</div>
						<br /> 
						<div>
							<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm"
								label-width="100px" class="demo-ruleForm">
								<el-form-item label="登陆账号" prop="username">
									<el-input v-model="ruleForm.username"></el-input>
								</el-form-item>

								<el-form-item label="登陆密码" prop="passwd">
									<el-input v-model="ruleForm.passwd" show-password></el-input>
								</el-form-item>
								<br />
								<!-- <a class="yu_a2">忘记密码?</a> --> 
								<el-form-item>
								    <el-button type="primary" @click="submitForm('ruleForm')">立即登陆</el-button>
								    <el-button @click="resetForm('ruleForm')">重置</el-button>
								  </el-form-item>
								  <!-- <div class="yu_a1">没有账号？<a class="yu_a2"> 立即注册</a></div> -->
							</el-form>
						</div>
					</el-col>
					<el-col :span="11">
						<el-image class="login-image" :src="imagesrc" :fit="fit"></el-image>
					</el-col>
				</el-row>
			</el-card>
		</el-col>
	</div>



</template>

<script>
	import './expand/style.css' 
	import post from "./expand/post.js" 
	 
	
	
	export default {
		data() {
			return {
				imagesrc: require('assets/image/undraw_Data_re_80ws.svg'),
				fit: "fill",
				ruleForm: {
					username: '',
					passwd: '' 
				},
				rules: {
					username: [{
							required: true,
							message: '请输入登陆账号',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 11,
							message: '长度在 3 到 11 个字符',
							trigger: 'blur'
						}
					],
					passwd: [{
						required: true,
						message: '请输入登陆密码',
						trigger: 'blur'
					},{
							min: 6,
							max: 12,
							message: '长度在 6 到 12 个字符',
							trigger: 'blur'
						}] 
				}
			}
		},

		methods: {
			//提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) { 
						this.login_post.m_main()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				
			}
		},
		created() { 
			this.login_post = new post(this);   
		} 
	}
</script>
 
